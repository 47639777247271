<template>
  <div v-if="error" class="full-height">
    <v-alert type="error" border="left" text :value="true">
      {{error}}
    </v-alert>
  </div>
  <div v-else class="all-width">
    <v-card loader-height="2" :loading="loading" :disabled="loading">
      <v-card-text>
        <div class="mb-8 d-flex align-center">
          <span class="title font-weight-bold" label>1.1</span>
          <v-icon class="mx-2">mdi-circle-small</v-icon>
          <div class="subtitle-2">Assignment Scope Information</div>
        </div>

        <v-dialog v-model="customscopedialog" max-width="600">
          <v-card :loading="customscopeloading" :disabled="customscopeloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Add New Scope</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="customscopedialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              <v-row>
                <v-col cols="12" class="my-0 py-1">
                  <span v-if='!customscopedata.group'>Adding as <strong>{{customscopedata.groupname}}</strong> area</span>
                  <span v-else-if="customscopedata.type === 'area'" >Adding under <strong>{{customscopedata.groupname}} area</strong></span>
                  <span v-else-if="customscopedata.type === 'verification'">Adding under <strong>{{customscopedata.groupname}}</strong> subarea</span>
                </v-col>
                <v-col cols="12" class="my-0 py-1">
                  <lb-string v-model="customscopedata.name" label="Name" :error="customscopeerror.name"/>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="addCustomScope()">Add Scope</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="deleteareadialog" max-width="600">
          <v-card :loading="deletearealoading" :disabled="deletearealoading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Delete!</span>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              This will delete the entire <strong>{{deleteareadata.name}}</strong> area and all the sub-area and audit procedure within this area.
              <br/>
              Do you want to proceed?
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn small @click="deleteareadialog = false" text>Cancel</v-btn>
              <v-btn small color="primary" @click="deleteCustomScope(deleteareadata._id)">Confirm</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="deletesubareadialog" max-width="600">
          <v-card :loading="deletesubarealoading" :disabled="deletesubarealoading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Delete!</span>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              This will delete the entire <strong>{{deletesubareadata.name}}</strong> sub-area along with all audit procedure within this sub-area.
              <br/>
              Do you want to proceed?
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn small @click="deletesubareadialog = false" text>Cancel</v-btn>
              <v-btn small color="primary" @click="deleteCustomScope(deletesubareadata._id)">Confirm</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="deleteverificationdialog" max-width="600">
          <v-card :loading="deleteverificationloading" :disabled="deleteverificationloading" loader-height="2" class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Delete!</span>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              This will delete the <strong>{{deleteverificationdata.name}}</strong> audit procedure.
              <br/>
              Do you want to proceed?
            </v-card-text>
            <v-card-text class="d-flex">
              <v-spacer></v-spacer>
              <v-btn small @click="deleteverificationdialog = false" text>Cancel</v-btn>
              <v-btn small color="primary" @click="deleteCustomScope(deleteverificationdata._id)">Confirm</v-btn>
            </v-card-text>
          </v-card>
        </v-dialog>

        <div class="d-flex">
          <v-btn small color="primary" class="view-on-hover-item mb-4" @click="customscopeerror = {};customscopedata = {groupname: 'Primary', type: 'area'};customscopedialog = true;"><v-icon left>mdi-plus</v-icon> Add Area</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" elevation="0" small @click="confirmScope()">Confirm Scope</v-btn>
        </div>
        <div class="d-flex mb-2 align-center">
          <div>
            <v-icon class="mr-1">mdi-playlist-plus</v-icon>
            Icon is show against all the custom scope
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-avatar size="15" color="grey lighten-2"></v-avatar>
            <span class="ml-2 mr-4">Total Procedures</span>
          </div>
          <div>
            <v-avatar size="15" color="success"></v-avatar>
            <span class="ml-2 mr-4">Automated Procedure</span>
          </div>
          <div>
            <v-avatar size="15" color="warning"></v-avatar>
            <span class="ml-2">Manual Procedures</span>
          </div>
        </div>
        <v-divider></v-divider>

        <!-- 1st list -->
        <div v-for="(v,k) in computedarealist" :key="k" class="hoverable">
          <div>
            <div :class="`d-flex align-center hoverable cursor-pointer px-2 view-on-hover-area ${(v.__type==='custom')?' border-left-warning':' border-left-grey'}`" v-ripple @click="updateAreaExpand(k)">
    
              <span class="py-4"><strong>{{v.name}}</strong></span>
              <v-btn v-if="v.__type === 'custom'" icon small class="ml-2 view-on-hover-item" @click.stop="deleteareadata=v;deleteareadialog=true"><v-icon>mdi-delete</v-icon></v-btn>
              <v-btn x-small color="primary" text class="ml-4 view-on-hover-item" @click.stop="customscopeerror = {};customscopedata = {group: v._id, groupname: v.name, type: 'area'};customscopedialog = true;"><v-icon left>mdi-plus</v-icon> Add Subarea</v-btn>
              <v-spacer></v-spacer>
              <v-icon left v-if="areaclose.indexOf(k) > -1">mdi-chevron-right</v-icon>
              <v-icon left v-else>mdi-chevron-down</v-icon>
            </div>
          </div>

          <!-- 2nd list -->
          <v-slide-y-transition>
            <div class="ml-2" v-if="areaclose.indexOf(k) === -1">
              
              <div v-if="Object.keys(computedsubarealist[v._id] || {}).length > 0">
                <div v-for="(vv,kk) in computedsubarealist[v._id]" :key="kk" class="hoverable">
                  
                  <div v-ripple class="cursor-pointer hoverable view-on-hover-area" @click="updateSubAreaExpand(vv._id)">
                    <div class="px-2 py-2">
                      <div class="d-flex align-center">
                        


                        <v-icon class="mr-2" :color="(vv.__type === 'custom')?'warning':''">mdi-circle-medium</v-icon>
                        <span class="mr-4">{{vv.name}}</span>
                      
                      

                        <v-icon v-if="vv.__type === 'custom'">mdi-playlist-plus</v-icon>
                        <v-btn x-small color="primary" text class="ml-2 view-on-hover-item" @click.stop="customscopeerror = {};customscopedata = {group: vv._id, groupname: vv.name, type: 'verification'};customscopedialog = true;"><v-icon left>mdi-plus</v-icon> Add Procedure</v-btn>
                        <v-spacer></v-spacer>
                        <v-chip small color="success" class="mr-2" v-if="(possibleverificationlist[vv._id] || []).length > 0">{{(possibleverificationlist[vv._id] || []).length}}</v-chip>
                        <v-chip small color="warning" class="mr-2" v-if="(Object.keys(verificationlist[vv._id] || {}).length + Object.keys(customverification[vv._id] || []).length - (possibleverificationlist[vv._id] || []).length) > 0">{{Object.keys(verificationlist[vv._id] || {}).length + Object.keys(customverification[vv._id] || []).length - (possibleverificationlist[vv._id] || []).length}}</v-chip>
                        <v-chip small color="" class="mr-2">{{Object.keys(verificationlist[vv._id] || {}).length + Object.keys(customverification[vv._id] || []).length}}</v-chip>
                        <v-icon left v-if="verificationopen.indexOf(vv._id) === -1">mdi-chevron-right</v-icon>
                        <v-icon left v-else>mdi-chevron-down</v-icon>
                      </div>
                    </div>
                  </div>

                  <!-- 3rs list -->
                  <v-slide-y-transition>
                    <div class="ml-8 mb-4" v-if="verificationopen.indexOf(vv._id) > -1">
                     
                      <div v-if="Object.keys(verificationlist[vv._id] || {}).length > 0 || Object.keys(customverification[vv._id] || {}).length > 0">

                        <!-- Custom list -->
                        <div v-for="(vvv,kkk) in customverification[vv._id]" :key="kkk" class="">
                          <div class="px-2 view-on-hover-area hoverable" v-ripple>
                            <div class="d-flex align-center">
                              <v-icon class="mr-2" color="warning">mdi-checkbox-marked</v-icon>
                              <span class="my-1">{{vvv}}</span>
                           
                              <v-icon class="ml-4">mdi-playlist-plus</v-icon>
                            </div>
                          </div>
                        </div>

                        <!-- System list -->
                        <div v-for="(vvv,kkk) in verificationlist[vv._id]" :key="kkk" class="">
                          <div class="px-2 view-on-hover-area hoverable cursor-pointer" v-ripple>
                            <div class="d-flex align-center">
                              <v-icon class="mr-2" color="success" v-if="(possibleverificationlist[vv._id] || []).indexOf(kkk) > -1">mdi-checkbox-marked</v-icon>
                              <v-icon class="mr-2" color="warning" v-else>mdi-checkbox-marked</v-icon>
                              <span class="my-1">{{vvv}}</span>
                              <v-icon class="mx-2 view-on-hover-item">mdi-help-circle-outline</v-icon>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div v-else class="grey--text font-weight-bold ml-4">
                        No Verification found under this subarea!
                      </div>
                    </div>
                  </v-slide-y-transition>

                </div>
              </div>
              <div v-else class="grey--text font-weight-bold ml-4 py-2">
                No Sub-area found under this area!
              </div>
            </div>
          </v-slide-y-transition>
          <v-divider></v-divider>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
// import pageHeader from '@/components/pageHeader'
// import managelist from '@/components/ManageList'

export default {
  name: 'assignment_setup_scope',
  // props: ['props'],
  data: function(){
    return {
      loading: false,
      counter: 1,
      arealist: [],
      areaclose: [],
      verificationopen: [],
      subarealist: {},
      // selectedscope: [],
      customarea: [],
      customsubarea: {},
      customverification: {},
      selectedgroup: [],
      addedscope: [],
      possibleverificationlist: {},
      verificationlist: {},
      error: "",
      customscopeloading: false,
      customscopedata: {},
      customscopedialog: false,
      customscopeerror: {},
      deleteareadialog: false,
      deletearealoading: false,
      deleteareadata: {},
      deletesubareadialog: false,
      deletesubarealoading: false,
      deletesubareadata: {},
      deleteverificationdialog: false,
      deleteverificationloading: false,
      deleteverificationdata: {},
    }
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData(full = true) {
      if(full){
        this.areaclose = [];
        this.verificationopen = [];
      }
      this.loading = false;
      this.arealist = [];
      this.counter = 1;
      this.subarealist = {};
      this.possibleverificationlist = {};
      this.verificationlist = {};
      this.scolelist = {};

      this.customarea = [];
      this.customsubarea = {};
      this.customverification = {};
      this.addedscope = [];
      this.error = "";
      this.customscopeloading = false;
      this.customscopedata = {};
      this.customscopedialog = false;
      this.customscopeerror = {};
      this.deleteareadialog = false;
      this.deletearealoading = false;
      this.deleteareadata = {};
      this.deletesubareadialog = false;
      this.deletesubarealoading = false;
      this.deletesubareadata = {};
      this.deleteverificationdialog = false;
      this.deleteverificationloading = false;
      this.deleteverificationdata = {};
      this.getData();
    },
    getData(){
      this.error = "";
      this.loading = true;
      this.axios.post("/v2/ia/assignment/scopelist/"+this.id).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          let data = dt.data.data[0];
          
          
          this.arealist = data.area;
          let subareaids = [];

          for (const i of (data.area || [])) {
            this.arealist[i._id] = i;
          }

          for (const j of (data.subarea || [])) {
            this.subarealist[j.group] = this.subarealist[j.group] || [];
            this.subarealist[j.group].push(j);
            subareaids.push(j._id);
          }
          return this.axios.post("/v2/ia/assignment/getverificationlist/"+this.id, {data: subareaids});
        }else throw new Error ("No scope details found");
      }).then(dt=> {
        if(dt.data.status === "success" && dt.data.data.length){
          for (let i = 0; i < (dt.data.data || []).length; i++) {
            const el = (dt.data.data || [])[i];
            for (let j = 0; j < el.area.length; j++) {
              const ael = el.area[j];
              this.verificationlist[ael] = this.verificationlist[ael] || {};
              this.verificationlist[ael][el._id] = el.name;
            }
          }
         
          return this.axios.post("/v2/ia/assignment/possibleautomatedacopelist/"+this.id);
        }else throw new Error ("No verifications found");
      }).then(dt=> {
        if(dt.data.status === "success"){
          for (let i = 0; i < (dt.data.data || []).length; i++) {
            const el = (dt.data.data || [])[i];
            for (let j = 0; j < el.area.length; j++) {
              const ael = el.area[j];
              this.possibleverificationlist[ael] = this.possibleverificationlist[ael] || [];
              this.possibleverificationlist[ael].push(el._id);
            }
          }
          
          return this.axios.post("/v2/ia/assignment/getscopesetup/"+this.id);
        }else throw new Error ("Error fething possible automated procedure list");
      }).then(dt=> {
        if(dt.data.status === "success"){
          
          if(dt.data.data.length > 0){
            for (let i = 0; i < dt.data.data.length; i++) {
              const el = dt.data.data[i];
              if(el.type === "area" && !el.group) this.customarea.push(el);
              else if(el.type === "area"){
                this.customsubarea[el.group] = this.customsubarea[el.group] || [];
                this.customsubarea[el.group].push(el);
                
              }
              else if(el.type === "verification"){
                
                this.customverification[el.group] = this.customverification[el.group] || {};
                this.customverification[el.group][el._id] = el.name;
                
              }
            }
          }
        }else throw new Error("Error fething selected scope details");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.counter++;
        this.loading = false;
      })
    },
   
    updateAreaExpand(k){
      let index = this.areaclose.indexOf(k);
      if(index > -1) this.areaclose.splice(index, 1);
      else this.areaclose.push(k);
    },
    updateSubAreaExpand(k){
     
      let index = this.verificationopen.indexOf(k);
      if(index > -1) this.verificationopen.splice(index, 1);
      else this.verificationopen.push(k);
    },
    confirmScope(){
      this.error = "";
      this.loading = true;
      this.axios.post("/v2/ia/assignment/confirmscope/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.$store.commit("sbSuccess", "Saved!");
          this.refreshData();
        }else throw new Error (dt.data.message || "Error processing request");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    addCustomScope(){
      this.customscopeloading = true;
      this.customscopeerror = {};
      Promise.resolve().then(() => {
        return this.axios.post("/v2/ia/assignment/addcustomscope/"+this.id, {data: this.customscopedata});
      }).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.$store.commit("sbSuccess", "Added!");
          this.refreshData(false);
        }else {
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.customscopeerror = dt.data.data[0].index0;
          }
          throw new Error (dt.data.message || "Unknown error!");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.customscopeloading = false;
      })
    },
    deleteCustomScope(id){
      this.deletearealoading = true;
      this.deletesubarealoading = true;
      this.deleteverificationloading = true;
      this.customscopeerror = {};
      Promise.resolve().then(() => {
        return this.axios.post("/v2/ia/assignment/deletecustomscope/"+id);
      }).then(dt => {
        if(dt.data.status === "success" && (dt.data.deletedCount || 0) > 0){
          this.$store.commit("sbSuccess", "Deleted!");
          this.deleteareadialog = false;
          this.deletesubareadialog = false;
          this.deleteverificationdialog = false;
          this.refreshData(false);
        }else {
          throw new Error (dt.data.message || "Unknown error!");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
      this.deletearealoading = false;
      this.deletesubarealoading = false;
      this.deleteverificationloading = false;
      })
    },
  },
  computed: {
    computedarealist: function(){
      let newalist = [...this.arealist];

      for (const i of this.customarea) {
        const el = {...i};
        el.__type = "custom";
        newalist.unshift(el);
      }
      return newalist;
    },
    computedsubarealist: function(){
      let newsblist = {...this.subarealist};
      for (const k in this.customsubarea) {
        if (Object.hasOwnProperty.call(this.customsubarea, k)) {
          const el = this.customsubarea[k];
          for (let i = 0; i < el.length; i++) {
            const sel = el[i];
            let nd = {...sel};
            nd.__type = "custom";
            if(Object.prototype.hasOwnProperty.call(newsblist,k)) newsblist[k].unshift(nd);
            else newsblist[k] = [nd];            
          }
        }
      }
      return newsblist;
    }
  },
}
</script>
