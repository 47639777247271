<template>
  <div v-if="pageloading">
    <v-skeleton-loader type="card-heading, list-item-three-line"></v-skeleton-loader>
  </div>
  <div v-else-if="error" class="full-height">
    <v-alert type="error" border="left" text :value="true">
      {{error}}      
    </v-alert>
  </div>
  <div v-else>
    <div class="all-height d-flex vertical-setup">
        <div class="content-window">
            <div v-if="tab === 'Scope Mapping'" key="3" class="content-box">
              <scoping :id="id"/>
            </div>
            <div v-else-if="tab === 'PBC'" key="4" class="content-box">
              <pbc :id="id"/>
            </div>
            <div v-else-if="tab === 'Testing Routine'" key="4" class="content-box">
              <testing :id="id"/>
            </div>
            <div v-else-if="tab === 'Follow-up'" key="4" class="content-box">
              <followup :id="id"/>
            </div>

         
        </div>
    </div>
  </div>
</template>

<script>
import scoping from './Scoping';
import pbc from './PBC';
import testing from './Testing';
import followup from './FollowUp';


export default {
  name: 'assignment_setup',
  data: function(){
    return {
      tab: "",
      id: '',
      loading: false,
      pageloading: false,
      error: "",
      scopelist: [],
      selectedscope: [],
      updatescopeerror: {},
      status: {scope: false, pbc: false, file: false, inputparameter: false, testing: false, followup: false, draftreport: false, managementresponse: false, finalreport: false},
    }
  },
  components: {
    scoping, pbc, testing, followup
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  beforeDestroy(){
    this.$store.commit("setCustomMenu", []);
  },

  methods: {
    refreshData() {
      this.tab = "";
      this.id = '';
      this.loading = false;
      this.pageloading = false;
      this.error = "";
      this.scopelist = [];
      this.selectedscope = [];
      this.updatescopeerror = {};
      this.status = {scope: false, pbc: false, file: false, inputparameter: false, testing: false, followup: false, draftreport: false, managementresponse: false, finalreport: false};
      this.id = this.$route.params.id || "";
      this.addlocationdata={};
      this.navigateHash();
      this.getData();
    },
    navigateHash(){
      if(this.$route.hash === "#final") this.tab = "Final";
      else if(this.$route.hash === "#managementresponse") this.tab = "Management Response";
      else if(this.$route.hash === "#draft") this.tab = "Draft";
      else if(this.$route.hash === "#followup") this.tab = "Follow-up";
      else if(this.$route.hash === "#testing") this.tab = "Testing Routine";
      else if(this.$route.hash === "#pbc") this.tab = "PBC";
      else if(this.$route.hash === "#inputparameter") this.tab = "Input Parameter Config";
      else if(this.$route.hash === "#file") this.tab = "File Config";
      else this.tab = "Scope Mapping"
    },
    getData(){
      this.errors = {};
      this.pageloading = true;
      this.axios.post("/v2/ia/assignment/get/"+this.id).then(dt=> {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          
          let assignmentSetup = ((dt.data.data[0] || {}).assignment || [])[0] || {};
          if(assignmentSetup.setup_scope) this.status.scope = true;
         

          let custommenu = [
            { divider: true},
            {title: 'Assignment Progress', icon: 'mdi mdi-view-dashboard-outline', href: '/ia/assignment/view/'+this.id},
            { header: true, title: 'Assignment Setup' },
            {title: 'Scope Mapping', icon: this.status.scope?'mdi mdi-check-circle-outline':'mdi mdi-timer-sand', href: '/ia/assignment/setup/'+this.id, hash: '#scope'},
            {title: 'PBC', icon: this.status.pbc?'mdi mdi-check-circle-outline':'mdi mdi-timer-sand', href: '/ia/assignment/setup/'+this.id, hash: '#pbc'},
            { header: true, title: 'Testing' },
            {title: 'Testing Routine', icon: this.status.testing?'mdi mdi-check-circle-outline':'mdi mdi-timer-sand', href: '/ia/assignment/setup/'+this.id, hash: '#testing'},
            {title: 'Follow-up Audit', icon: this.status.followup?'mdi mdi-check-circle-outline':'mdi mdi-timer-sand', href: '/ia/assignment/setup/'+this.id, hash: '#followup'},
          ];
          this.$store.commit("setCustomMenu", custommenu);
        }else {
          this.$store.commit("setCustomMenu", []);
          this.error = "No assignment found!!"
          throw new Error ("Error fething the assignment details");
        }
        
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.pageloading = false;
      })
    },
    updateRisk(){
      this.loading = true;
      let uploadFileFlag = true;
      this.riskerrors = {};
      Promise.resolve().then(() => {
        if(this.riskdata.file && !this.engagementletter) uploadFileFlag = false;
        if(uploadFileFlag) return this.$nova.uploadFile(this.axios, this.engagementletter);
      }).then(dt => {
        let proceedFlag = false;
        if(uploadFileFlag){
          if(dt.data.status === "success" && dt.data.data.length > 0){
            this.riskdata.file = dt.data.data[0]._id;
            this.engagementletter.id = dt.data.data[0]._id;
            proceedFlag = true;
          }else throw new Error ("Unable to upload file");
        }else proceedFlag = true;
        if(proceedFlag) return this.axios.post("/v2/ia/engagement/updaterisk/"+this.id, {data: this.riskdata});
      }).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.$store.commit("sbSuccess", "Scope Mapping Updated");
          this.refreshData();
        }else{
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.riskerrors = ((dt.data.data[0].index0 || {}).riskinfo[0]).index0;
          }
          throw new Error (dt.data.message || "Unknown error!");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    updateLocation(){
      this.loading = true;
      this.updatelocationerror = {};
      Promise.resolve().then(() => {
        return this.axios.post("/v2/ia/engagement/updatelocation/"+this.id, {data: this.selectedlocation});
      }).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.$store.commit("sbSuccess", "Location Information Updated");
          this.refreshData();
        }else {
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.updatelocationerror = dt.data.data[0].index0;
          }
          throw new Error (dt.data.message || "Unknown error!");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    addNewLocation(){
      this.locationloading = true;
      this.addlocationdataerror = {};
      Promise.resolve().then(() => {
        return this.axios.post("/v2/ia/engagement/addnewlocation", {data: this.addlocationdata});
      }).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.$store.commit("sbSuccess", "New location added");
          this.locationdialog = false;
          this.refreshData();
        }else {
          if (dt.data.data.length > 0){
            if((dt.data.data[0].index0 ?? {}).location) {
              let errlist = dt.data.data[0].index0.location[0] ?? [];
              
              for (const k in errlist ?? []) {
                if (Object.hasOwnProperty.call(errlist, k)) {
                  let el = errlist[k];
                  
                  this.addlocationdataerror[this.selectedlocation[k.split("").pop()]] = el[0] || "";                
                }
              }
            }
          }
          throw new Error (dt.data.message || "Unknown error!");

        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.locationloading = false;
      })
    },
    updateScope(){
      this.loading = true;
      this.updatescopeerror = {};
      Promise.resolve().then(() => {
        return this.axios.post("/v2/ia/engagement/updatescope/"+this.id, {data: this.selectedscope});
      }).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.$store.commit("sbSuccess", "Scope Information Updated");
          this.refreshData();
        }else {
          if (dt.data.data.length > 0){
            if((dt.data.data[0].index0 ?? {}).scope) {
              let errlist = dt.data.data[0].index0.scope[0] ?? [];
              for (const k in errlist ?? []) {
                if (Object.hasOwnProperty.call(errlist, k)) {
                  let el = errlist[k];
                  this.updatescopeerror[this.selectedscope[k.split("").pop()]] = el[0] || "";                
                }
              }
            }
          }
          throw new Error (dt.data.message || "Unknown error!");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  watch: {
    "$store.state.client": function(){
      this.refreshData();
    },
    "$route.hash": function(){
      this.navigateHash();
    }
  }
}
</script>
